import React from 'react';
import { sanitizeHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 


const OverviewPart = (props) => {
  const { descrizione, titolo } = props;


  const createMarkup = (htmlContent) => {
    return { __html: sanitizeHtml(htmlContent) };
  };

  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <h4>{titolo || "Dettagli del corso"}</h4>
          <p
            dangerouslySetInnerHTML={
              descrizione
                ? createMarkup(descrizione)
                : { __html: "in fase di revisione" }
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewPart;