import React from "react";

function PDFViewer({ pdfUrl }) {
  return (
    <iframe
      src={pdfUrl}
      style={{ width: "100%", height: "1000px" }}
      title="PDF Viewer"
    ></iframe>
  );
}

const ConsiglioPart = (props) => {
  const { fileDoc} = props;
  
  return (
    <div className="content pt-30 pb-30 pl-30 pr-30 white-bg">
      <PDFViewer pdfUrl={fileDoc} />
    </div>
  );
};
export default ConsiglioPart;
