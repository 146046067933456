
import React from 'react';
import { Helmet } from 'react-helmet';
import BlogMain from './BlogMain';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';

import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';


// Image
import favIcon from "../../assets/img/fav.png";
import Logo from "../../assets/img/logo/logo.png";
import footerLogo from "../../assets/img/logo/logo.png";
import StikyLogo from "../../assets/img/logo/logo-verde.png";


const Blog = () => {
    return (
      <React.Fragment>
        <Helmet>
          <title>Poliziano Press | Istituto paritario Poliziano</title>
          <link rel="icon" href={favIcon} />
          <meta
            name="description"
            content="Notizie sulle attività che si svolgono al Poliziano"
          />
        </Helmet>
        <OffWrap />
        <Header
          parentMenu={100}
          secondParentMenu="blogSingle"
          headerNormalLogo={Logo}
          headerStickyLogo={StikyLogo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
        />

        <hr />

        {/* Blog Main */}
        <div class="rs-about style1 pt-82  md-pt-50 ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-9 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                <div class="sec-title mb-44 md-mb-0">
                  <div class="sub-title orange">Istituto Poliziano</div>
                  <h2 class="title mb-16">Poliziano Press</h2>
                  <p class="bold-text mb-22">
                    Poliziano Press è il giornalino online degli studenti del
                    Poliziano che si propone di offrire uno sguardo divertente e
                    genuino, ma non per questo superficiale, sulla vita del
                    nostro istituto. Il progetto si articola in contenuti social
                    prodotti dagli studenti sotto la supervisione dei
                    professori, con l’obiettivo di stimolare riflessioni più
                    ampie su tematiche e suggestioni nate tra i banchi della
                    nostra scuola e di coinvolgere più attivamente i ragazzi nel
                    processo educativo e relazionale reciproco.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlogMain />
        {/* Blog Main End */}

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}
      </React.Fragment>
    );
}


export default Blog;

