import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import bannerImg from "../../assets/img/banner/home12/1.png";

import shapeImg1 from "../../assets/img/banner/home12/dotted-shape.png";
import shapeImg2 from "../../assets/img/banner/home12/intro-box.png";

import bgImg from "../../assets/img/banner/home12/banner-home12.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const BannerStyleEight = () => {
  const [bannerData, setbannerData] = useState({
    titoloBanner: "",
    contattiBanner: "",
    sottotitoloBanner: "",
  });

  const fetchbannerData = async () => {
    const query = `
{
  pageBy(pageId: 10) {
    headery {
      titoloBanner
      contattiBanner
      sottotitoloBanner
    }
  }
}
`;

    try {
      // Esegui la richiesta all'endpoint GraphQL di WordPress
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        // Imposta i dati nello stato
        setbannerData(result.data?.pageBy.headery);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchbannerData();
  }, []);

  if (!bannerData) {
    return <div>Caricamento in corso...</div>;
  }

  const titolo = bannerData ? bannerData.titoloBanner : "";
  const contatti = bannerData ? bannerData.contattiBanner : "";
  const sottotitolo = bannerData ? bannerData.sottotitoloBanner : "";

  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style10" style={bgStyle}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pl-60 relative order-last">
              <div className="img-part">
                <img className="up-down-new" src={bannerImg} alt="" />
              </div>
            </div>
            <div className="col-lg-6 pr-0">
              <div className="banner-content">
                <div className="sl-sub-title">{sottotitolo ? sottotitolo : "POLIZIANO"}</div>
                <h1 className="sl-title">{titolo ? titolo : "BENVENUTO AL POLIZIANO"}</h1>
                <div className="banner-btn">
                  <Link className="readon green-banner" to="/servizi/contatti">
                    {contatti ? contatti : "CONTATTACI"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-intro-box">
          <div className="shape-img">
            <img className="up-down-new" src={shapeImg1} alt="" />
          </div>
          <div className="intro-img">
            <img className="spine2" src={shapeImg2} alt="" />
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerStyleEight;
