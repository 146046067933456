import React from 'react';
import CourseDetailsPart from './CourseDetailsPart';
import ScrollToTop from '../../../components/Common/ScrollTop';

const CourseDetailsMain = (props) => {
  const { foto1, foto2, foto3, fileDoc, fileLibri, filePro, descrizione, titolo } = props;
    return (
      <React.Fragment>
        {/* CourseDetails Start */}
        <CourseDetailsPart
          foto1={foto1}
          foto2={foto2}
          foto3={foto3}
          fileDoc={fileDoc}
          filePro={filePro}
          fileLibri={fileLibri}
          descrizione={descrizione}
          titolo={titolo}
        />
        {/* CourseDetails End */}

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}
      </React.Fragment>
    );
}

export default CourseDetailsMain;