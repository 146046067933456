import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getIdFromPath } from "./queries";
//import { stripHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SectionTitle from "./textSection";
import GalleryPart from "./Galleriafoto";
import ContactForm from "/Users/angelomontini/Documents/React/educavo-react/src/pages/home/ContactForm.js";
import Logo from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/logo/logo.png";
import footerLogo from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/logo/logo.png";
import Imga3 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/about/about2orange.png";
import CanvasLogo from "../../../assets/img/logo/logo-verde.png";
import StikyLogo from "../../../assets/img/logo/logo-verde.png";


const CourseSingle = () => {
    const location = useLocation(); 
    const id = getIdFromPath(location.pathname);
    const [pagData, setpagData] = useState([]); 

  useEffect(() => { 
    const fetchpagData = async () => {
      const query = `
      {
  page(id: "${id}", idType: DATABASE_ID) {
    title
    content
    parentDatabaseId
    pagineGeneriche {
      sintesi2
      immagine1 {
        mediaItemUrl
      }
      immagine2 {
        mediaItemUrl
      }
      immagine3 {
        mediaItemUrl
      }
      titoloAllegato
      allegato {
        mediaItemUrl
      }
      titoloAllegato2
      allegato2 {
        mediaItemUrl
      }
      titoloAllegato3
      allegato3 {
        mediaItemUrl
      }
      titoloAllegato4
      allegato4 {
        mediaItemUrl
      }
      titoloAllegato5
      allegato5 {
        mediaItemUrl
      }
      titoloAllegato6
      allegato6 {
        mediaItemUrl
      }
      titoloAllegato7
      allegato7 {
        mediaItemUrl
      }
      titoloAllegato8
      allegato8 {
        mediaItemUrl
      }
      titoloAllegato9
      allegato9 {
        mediaItemUrl
      }
      titoloAllegato10
      allegato10 {
        mediaItemUrl
      }
    }
    featuredImage {
      node {
        mediaItemUrl
      }
    }
  }
}
      `;

      try {
        const response = await fetch(
          "https://gestione.istitutopoliziano.it/homo",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ query }),
          }
        );

        // Estrai i dati dalla risposta
        const result = await response.json();

        if (response.ok) {
          setpagData(result.data?.page);
        } else {
          console.error("Errore nella richiesta GraphQL:", result.errors);
        }
      } catch (error) {
        console.error("Errore nella richiesta di rete:", error);
      }
    };
    fetchpagData();
  }, [id]);

 if (!pagData) {
   return <div>Caricamento in corso...</div>;
 }

   

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {pagData
            ? `${pagData.title} | Istituto paritario Poliziano`
            : "Istituto paritario Poliziano"}
        </title>
        <meta name="description" content={pagData.corso?.sintesi2} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu={pagData.parentDatabaseId}
        headerNormalLogo={Logo}
        headerStickyLogo={StikyLogo}
        CanvasLogo={CanvasLogo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
      />

      <hr />

      <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-md-6 col-lg-3  order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
              {location.pathname === "/iscrizioni" ? (
                <div className="rs-free-contact">
                  <div className="sec-title3">
                    <h2 className="title white-color">
                      Informazioni Iscrizione
                    </h2>
                  </div>
                  <ContactForm />
                </div>
              ) : (
                <div className="img-part">
                  <img
                    className="about-main"
                    src={pagData.featuredImage?.node?.mediaItemUrl || Imga3}
                    alt="Poliziano"
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-9 pr-70 md-pr-14">
              <div className="about-content">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-44 md-mb-0"
                  subtitleClass="sub-title orange"
                  subtitle="Istituto Poliziano"
                  titleClass="title mb-16"
                  title={pagData.title}
                  descClass="bold-text mb-22"
                  description={pagData.pagineGeneriche?.sintesi2}
                  secondDescClass="desc"
                  secondDescription={pagData.content}
                  allegato={pagData.pagineGeneriche?.allegato?.mediaItemUrl}
                  titoloAllegato={pagData.pagineGeneriche?.titoloAllegato}
                  allegato2={pagData.pagineGeneriche?.allegato2?.mediaItemUrl}
                  titoloAllegato2={pagData.pagineGeneriche?.titoloAllegato2}
                  allegato3={pagData.pagineGeneriche?.allegato3?.mediaItemUrl}
                  titoloAllegato3={pagData.pagineGeneriche?.titoloAllegato3}
                  allegato4={pagData.pagineGeneriche?.allegato4?.mediaItemUrl}
                  titoloAllegato4={pagData.pagineGeneriche?.titoloAllegato4}
                  allegato5={pagData.pagineGeneriche?.allegato5?.mediaItemUrl}
                  titoloAllegato5={pagData.pagineGeneriche?.titoloAllegato5}
                  allegato6={pagData.pagineGeneriche?.allegato6?.mediaItemUrl}
                  titoloAllegato6={pagData.pagineGeneriche?.titoloAllegato6}
                  allegato7={pagData.pagineGeneriche?.allegato7?.mediaItemUrl}
                  titoloAllegato7={pagData.pagineGeneriche?.titoloAllegato7}
                  allegato8={pagData.pagineGeneriche?.allegato8?.mediaItemUrl}
                  titoloAllegato8={pagData.pagineGeneriche?.titoloAllegato8}
                  allegato9={pagData.pagineGeneriche?.allegato9?.mediaItemUrl}
                  titoloAllegato9={pagData.pagineGeneriche?.titoloAllegato9}
                  allegato10={pagData.pagineGeneriche?.allegato10?.mediaItemUrl}
                  titoloAllegato10={pagData.pagineGeneriche?.titoloAllegato10}
                />
                {/* Section Title End */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GalleryPart
        img1={pagData.pagineGeneriche?.immagine1?.mediaItemUrl}
        img2={pagData.pagineGeneriche?.immagine2?.mediaItemUrl}
        img3={pagData.pagineGeneriche?.immagine3?.mediaItemUrl}
      />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
    </React.Fragment>
  );
};

export default CourseSingle;
