import React, { useState, useEffect } from "react";
import SinglePostSidebar from "./SinglePostSidebar";
import SinglePostSix from "../../components/Blog/SinglePostTwo";
import { stripHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js";
import { formatDate } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import blogImg1 from "../../assets/img/blog/inner/1.jpg";


const BlogMain = () => {
  const [blogIndexData, setblogIndexData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;
  const totalPages = Math.ceil(blogIndexData.length / postsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const fetchblogIndexData = async () => {
    const query = `{
  posts(where: {categoryId: 4}){
    nodes {
      title
      date
      excerpt
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      notizie_altro {
        autore
      }
      slug
    }
  }
}`;

    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        setblogIndexData(result.data?.posts.nodes);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchblogIndexData();
  }, []);

  if (!blogIndexData) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    <div className="rs-inner-blog orange-style pt-20 pb-100 md-pt-70 md-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 order-last">
            <div className="widget-area">
              <SinglePostSidebar />
            </div>
          </div>

          <div className="col-lg-8 pr-50 md-pr-16">
            <div className="row">
              <div className="col-lg-12 mb-70 md-mb-50">
                {blogIndexData
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .slice(
                    (currentPage - 1) * postsPerPage,
                    currentPage * postsPerPage
                  )
                  .map((value, i) => (
                    <SinglePostSix
                      blogImage={
                        value.featuredImage?.node?.mediaItemUrl || blogImg1
                      }
                      blogAuthor={value.notizie_altro?.autore}
                      blogCategory={value.categories.nodes
                        .map((node) => node.name)
                        .join(", ")}
                      blogPublishedDate={formatDate(value.date)}
                      blogTitle={value.title}
                      blogDesc={stripHtml(value.excerpt)}
                      blogSlug={value.slug}
                      blogButtonClass="blog-button"
                      blogButtonText="Leggi"
                      key={i}
                    />
                  ))}
                <div className="pagination">
                  <button
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    className="buttonpagination"
                  >
                    Previous
                  </button>

                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      className={
                        currentPage === index + 1
                          ? "active buttonpagination"
                          : "buttonpagination"
                      }
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                    className="buttonpagination"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogMain;
