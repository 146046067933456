import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import CourseDetailsMain from "./CourseDetailsMain";
import footerLogo from "../../../assets/img/logo/logo.png";
import Logo from "../../../assets/img/logo/logo.png";

const CourseSingle = () => {
  const [corsoData, setcorsoData] = useState([]);

  const fetchcorsoData = async () => {
    const query = `
   {
  pageBy(pageId: 146) {
    title
    corso {
      sintesi2
      documentoConsiglioDiClasse {
        mediaItemUrl
      }
      listaLibri {
        mediaItemUrl
      }
      programmi {
        mediaItemUrl
      }
      immagineuno {
        mediaItemUrl
      }
      immaginedue {
        mediaItemUrl
      }
      immagineter {
        mediaItemUrl
      }
    }
    content
    featuredImage {
      node {
        mediaItemUrl
      }
    }
  }
}
`;
    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        setcorsoData(result.data?.pageBy);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchcorsoData();
  }, []);

  if (!corsoData) {
    return <div>Caricamento in corso...</div>;
  }

   return (
     <React.Fragment>
       <Helmet>
         <title>
           {corsoData
             ? `${corsoData.title} | Istituto paritario Poliziano`
             : "Istituto paritario Poliziano"}
         </title>
         <meta name="description" content={corsoData.corso?.sintesi2} />
       </Helmet>
       <OffWrap />
       <Header
         parentMenu={corsoData.parentDatabaseId}
         headerNormalLogo={Logo}
         headerStickyLogo={Logo}
         mobileNormalLogo={Logo}
         CanvasClass="right_menu_togle hidden-md"
         headerClass="full-width-header header-style1 home8-style4"
         TopBar="enable"
         TopBarClass="topbar-area home8-topbar"
       />

       {/* breadcrumb-area-start */}
       <SiteBreadcrumb
         pageTitle={corsoData.title}
         breadcrumbsImg={corsoData.featuredImage?.node?.mediaItemUrl}
         pageName="Dettagli del corso"
         pageCategory="Corsi"
       />
       {/* breadcrumb-area-start */}

       {/* Course Details Main */}
       <CourseDetailsMain
         titolo={corsoData.title}
         foto1={corsoData.corso?.immagineuno?.mediaItemUrl}
         foto2={corsoData.corso?.immaginedue?.mediaItemUrl}
         foto3={corsoData.corso?.immagineter?.mediaItemUrl}
         fileDoc={corsoData.corso?.documentoConsiglioDiClasse?.mediaItemUrl}
         fileLibri={corsoData.corso?.listaLibri?.mediaItemUrl}
         filePro={corsoData.corso?.programmi?.mediaItemUrl}
         descrizione={corsoData.content}
       />
       {/* Course Details Main */}

       <Footer
         footerClass="rs-footer home9-style main-home"
         footerLogo={footerLogo}
       />
     </React.Fragment>
   );
};

export default CourseSingle;
