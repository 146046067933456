import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import CourseSingle from "../../components/Courses/CourseSingle";
import image1 from "../../assets/img/courses/2.jpg";


const Courses = () => {
  const [corsoData, setcorsoData] = useState([]);

  const corsiInfo = {
    "cG9zdDoxNDk=": {
      link: "/corsi/amministrazione",
    },
    "cG9zdDoxNDY=": {
      link: "/corsi/sociale",
    },
    "cG9zdDoxNDQ=": {
      link: "/corsi/linguistico",
    },
    "cG9zdDoxNDI=": {
      link: "/corsi/umane",
    },
    "cG9zdDoxNDA=": {
      link: "/corsi/applicate",
    },
    "cG9zdDoxMzg=": {
      link: "/corsi/scientifico",
    },
    "cG9zdDo0ODg=": {
      link: "/corsi/madeitaly",
    },
  };

  const fetchcorsoData = async () => {
    const query = `
   {
  pages(where: {parent: "cG9zdDo5Ng=="}) {
    edges {
      node {
        title
        corso {
          sintesi2
          immagineuno {
            mediaItemUrl
          }
          sigla
        }
        id
      }
    }
  }
}
`;

    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        setcorsoData(result.data?.pages?.edges);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchcorsoData();
  }, []);

  if (!corsoData) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    <div className="rs-popular-courses main-home home12-style pt-90 pb-100 md-pt-0 md-pb-0">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title4 text-center mb-50"
          subtitleClass="sub-title"
          subtitle="Poliziano"
          titleClass="Corsi di Studio"
          title="Corsi di Studio"
        />
        <div className="row">
          {corsoData.map((edge, index) => {
            const corsoID = edge.node.id;
            const corsoInfo = corsiInfo[corsoID];
            return (
              <div className="col-lg-4 col-md-6 mb-30" key={index}>
                <CourseSingle
                  itemClass="courses-item"
                  image={
                    edge.node.corso?.immagineuno?.mediaItemUrl
                      ? edge.node.corso?.immagineuno?.mediaItemUrl
                      : image1
                  }
                  title={edge.node.title}
                  sigla={edge.node.corso?.sigla}
                  courseLink={corsoInfo ? corsoInfo.link : "#"}
                  sintesi2={edge.node.corso?.sintesi2}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Courses;
