import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message" style={{
      color:"#fff",
      fontWeight:"600"
    }}>
      Il tuo messaggio è stato inviato con successo. Ti contatterò presto.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_b78y6ig",
        "template_pxm2cnk",
        e.target,
        "KbgcTlSe0OTA0TSaP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="row">
        <div className="col-lg-6 mb-30 col-md-12">
          <input
            className="from-control"
            type="text"
            id="name"
            name="fullname"
            placeholder="Nome"
            required
          />
        </div>
        <div className="col-lg-6 mb-30 col-md-12">
          <input
            className="from-control"
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            required
          />
        </div>
        <div className="col-lg-6 mb-30 col-md-12">
          <input
            className="from-control"
            type="text"
            id="phone"
            name="phone"
            placeholder="Numero di telefono"
            required
          />
        </div>
        <div className="col-lg-6 mb-30 col-md-12">
          <input
            className="from-control"
            type="text"
            id="subject"
            name="subject"
            placeholder="Corso d'interesse"
            required
          />
        </div>

        <div className="col-lg-12 mb-32">
          <textarea
            className="from-control"
            id="message"
            name="message"
            placeholder=" Messaggio"
            required=""
          ></textarea>
        </div>
      </div>
      <div className="form-btn">
        <input
          className=" readon submit-requset"
          type="submit"
          name="submit"
          value="Invia"
        />
      </div>
      <div className="title mb-40 md-mb-14">
        {result ? <Result /> : null}
      </div>
    </form>
  );
}
export default ContactForm;
