// utils.js

export function stripHtml(html) {
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Estrai giorno, mese e anno
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() restituisce un indice basato su 0, quindi +1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function sanitizeHtml(html) {
  const tagBody = "(?:[^\"'>]|\"[^\"]*\"|'[^']*')*";

  const tagOrComment = new RegExp(
    "<(?:" +
      // Commenti
      "!--(?:(?:-*[^->])*--+|-?)" +
      // Tag script e style
      "|script\\b" +
      tagBody +
      ">[\\s\\S]*?</script\\s*" +
      "|style\\b" +
      tagBody +
      ">[\\s\\S]*?</style\\s*" +
      // Tag self-closing che non sono <br>
      "|/(?!br\\b)[a-z][a-z0-9]*\\b" +
      tagBody +
      ">" +
      // Tag aperti o chiusi che non sono <br>, <h1>, <h2>, <h3>, <h4>, <h5>, <h6>
      "|(?<!br\\b|h[1-6]\\b)/?[a-z][a-z0-9]*\\b" +
      tagBody +
      ">" +
      ")>",
    "gi"
  );

  let oldHtml;
  do {
    oldHtml = html;
    html = html.replace(tagOrComment, "");
  } while (html !== oldHtml);

  return html;
}
