import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery"; // Assicurati di avere questa dipendenza
import Carousel, { Modal, ModalGateway } from "react-images"; // Assicurati di avere questa dipendenza
import Imga1 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/about/about-2.png";
import Imga2 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/about/about-5.png";
import Imga3 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/about/1.jpg";

const GalleryPart = ({ img1, img2, img3 }) => {
  const photos = [
    {
      src: img1 || Imga1,
      width: 1,
      height: 1,
    },
    {
      src: img2 || Imga3,
      width: 1,
      height: 1,
    },
    {
      src: img3 || Imga2,
      width: 1,
      height: 1,
    },
  ];

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <div className="rs-gallery pt-100 pb-70 md-pt-80 md-pb-50">
        <div className="container">
          <Gallery
            direction={"column"}
            photos={photos}
            onClick={openLightbox}
          />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </>
  );
};

export default GalleryPart;
