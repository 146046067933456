import React from 'react';
import Image1 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/courses/main-home/6.jpg"
import Image2 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/courses/main-home/5.jpg";
import Image3 from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/courses/main-home/3.jpg";


const CourseSidebar = (props) => {
const {
 foto1,
 foto2,
 foto3,
} = props;
 
    return (
      <div className="inner-column">
        <div className="row grid-area">
          <div className="col-md-12">
            <div className="image-grid">
              <img src={foto1 ? foto1 : Image1} alt="grid img" />
            </div>
          </div>
          <div className="col-md-12 mt-20">
            <div className="image-grid">
              <img src={foto2 ? foto2 : Image2} alt="grid img" />
            </div>
          </div>
          <div className="col-md-12 mt-20">
            <div className="image-grid">
              <img src={foto3 ? foto3 : Image3} alt="grid img" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default CourseSidebar;