import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 

const RecentPost = () => {
  const [eventData, seteventData] = useState([]);

  const fetcheventData = async () => {
    const query = `
    {
  posts(where: {categoryId: 4}) {
    nodes {
      title
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      slug
      notizie_altro {
        autore
        innerimage {
          mediaItemUrl
        }
      }
      date
    }
  }
}
`;

    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        seteventData(result.data?.posts.nodes);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetcheventData();
  }, []);

  if (!eventData) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    <div className="recent-posts-widget mb-50">
      <h3 className="widget-title">Notizie</h3>
      {eventData
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3)
        .map((value, i) => (
          <div className="show-featured" key={i}>
            <div className="post-img">
              <Link
                to={`/blog/${value.slug ? value.slug : ""}`}
                title={value.slug}
              >
                <img
                  src={
                    value.featuredImage?.node?.mediaItemUrl ||
                    value.notizie_altro?.innerimage?.mediaItemUrl
                  }
                  alt={value.title}
                />
              </Link>
            </div>
            <div className="post-desc">
              <Link
                to={`/blog/${value.slug ? value.slug : ""}`}
                title={value.slug}
              >
                {value.title}
              </Link>
              <span className="date">
                <i className="fa fa-calendar"></i>
                {formatDate(value.date)}
              </span>
              <span className="date" style={{ color: "#777" }}>
                <i className="fa fa-user-o"></i>
                {value.notizie_altro?.autore}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default RecentPost;
