import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesWidget = () => {
    
    return (
      <div className="widget-archives mb-50">
        <h3 className="widget-title">Link</h3>
        <ul>
          <li>
            <Link class="" to="/corsi/scientifico">
              Liceo Scientifico
            </Link>
          </li>
          <li>
            <Link class="" to="/corsi/applicate">
              Liceo Scientifico - Opzione Scienze Applicate
            </Link>
          </li>
          <li>
            <Link class="" to="/corsi/umane">
              Liceo delle Scienze Umane
            </Link>
          </li>
          <li>
            <Link class="" to="/corsi/linguistico">
              Liceo Linguistico
            </Link>
          </li>
          <li>
            <Link class="" to="/corsi/sociale">
              Liceo delle Scienze Umane opz. Economico-Sociale
            </Link>
          </li>
          <li>
            <Link class="" to="/corsi/amministrazione">
              Amministrazione, Finanza, Marketing
            </Link>
          </li>

          <li>
            <Link to="/blog" style={{ color: "#ff5421 " }}>
              Notizie
            </Link>
          </li>
          <li>
            <Link to="/eventi" style={{ color: "#ff5421 " }}>
              Eventi
            </Link>
          </li>
        </ul>
      </div>
    );
}

export default CategoriesWidget;