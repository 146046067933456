import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import SinglePost from "./SinglePost";
import { stripHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import { formatDate } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 

const BlogPart = () => {
  const blogSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [blogData, setblogData] = useState([]);

  const fetchblogData = async () => {
    const query = `{
  posts(where: {categoryId: 4}){
    nodes {
      title
      date
      excerpt(format: RENDERED)
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      notizie_altro {
        autore
      }
      slug
    }
  }
}`;

    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        setblogData(result.data?.posts.nodes);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchblogData();
  }, []);

  if (!blogData) {
    return <div>Caricamento in corso...</div>;
  }


  return (
    <React.Fragment>
      <Slider {...blogSettings}>
        {blogData
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3)
          .map((value, i) => (
            <SinglePost
              blogClass="blog-item"
              blogImage={value.featuredImage?.node?.mediaItemUrl}
              blogCategory={value.categories.nodes
                .map((node) => node.name)
                .join(", ")}
              blogTitle={value.title}
              blogDesc={stripHtml(value.excerpt)}
              blogPublishedDate={formatDate(value.date)}
              blogAuthor={value.notizie_altro?.autore}
              blogSlug={value.slug}
              key={i}
            />
          ))}
      </Slider>
    </React.Fragment>
  );
};

export default BlogPart;


