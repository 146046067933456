import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useParams} from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  formatDate,
  sanitizeHtml,
} from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import { stripHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

import SinglePostSidebar from "./SinglePostSidebar";


// Image
import favIcon from "../../assets/img/fav.png";
import Logo from "../../assets/img/logo/logo.png";
import footerLogo from "../../assets/img/logo/logo.png";
import StikyLogo from "../../assets/img/logo/logo-verde.png";



function getYoutubeVideoId(url) {
  if (!url) {
    return null;
  }
  const parts = url.split("=");
  if (parts.length > 1) {
    return parts[1];
  } else {
    return null;
  }
}

function Post() {
   const history = useHistory();
  const { slug} = useParams();
  const [postData, setpostData] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

  const createMarkup = (htmlContent) => {
    return { __html: sanitizeHtml(htmlContent) };
  };

  useEffect(() => {
    const fetchpostData = async () => {
      const query = `
{
  post(idType: SLUG, id: "${slug}") {
    categories {
      nodes {
        name
      }
    }
    notizie_altro {
      video
      banner {
        mediaItemUrl
      }
      autore
      innerimage {
        mediaItemUrl
      }
      uploadFile {
        mediaItemUrl
        title
      }
    }
    date
    slug
    title
    excerpt
    content
    featuredImage {
      node {
        mediaItemUrl
      }
    }
  }
}
`;

      try {
        const response = await fetch(
          "https://gestione.istitutopoliziano.it/homo",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ query }),
          }
        );

        const result = await response.json();
        if (response.ok && result.data?.post) {
          setpostData(result.data.post);
        } else {
          history.push("/404");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchpostData();
  }, [slug, history]);

  if (loading) {
    return <div>...</div>;
  }

  if (error) {
    return <div>Errore: {error}</div>; 
  }

    let videoId = null;
    if (postData && postData.notizie_altro && postData.notizie_altro.video) {
      videoId = getYoutubeVideoId(postData.notizie_altro.video);
    }



 return (
   <React.Fragment>
     <Helmet>
       <title>
         {postData
           ? `${postData.title} | Istituto paritario Poliziano`
           : "Istituto paritario Poliziano"}
       </title>
       <link rel="icon" href={favIcon} />
       <meta name="description" content={stripHtml(postData.excerpt)} />
     </Helmet>
     <OffWrap />
     <Header
       parentMenu={100}
       secondParentMenu="blogSingle"
       headerNormalLogo={Logo}
       headerStickyLogo={StikyLogo}
       mobileNormalLogo={Logo}
       CanvasClass="right_menu_togle hidden-md"
       headerClass="full-width-header header-style1 home8-style4"
       TopBar="enable"
       TopBarClass="topbar-area home8-topbar"
     />

     <SiteBreadcrumb
       pageTitle={postData.title}
       pageCategory="Blog"
       linkBread="/blog"
       pageName="Notizia"
       breadcrumbsImg={postData.notizie_altro?.banner?.mediaItemUrl}
     />

     {/* Blog Details Start */}
     <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
       <div className="container">
         <div className="row">
           <div className="col-lg-8 pr-50 md-pr-14">
             <div className="blog-deatails">
               <div className="bs-img">
                 <img
                   src={postData.featuredImage?.node?.mediaItemUrl}
                   alt="Poliziano post"
                 />
               </div>
               <div className="blog-full">
                 <ul className="single-post-meta">
                   <li>
                     <span className="p-date">
                       {" "}
                       <i className="fa fa-calendar-check-o"></i>{" "}
                       {formatDate(postData.date)}
                     </span>
                   </li>
                   <li>
                     <span className="p-date">
                       {" "}
                       <i className="fa fa-user-o"></i>{" "}
                       {postData.notizie_altro?.autore}
                     </span>
                   </li>
                   <li className="Post-cate">
                     <span className="tag-line">
                       <i className="fa fa-tag"></i>
                       {postData.categories?.nodes.map((category, index) => (
                         <span key={index}>{category.name}</span>
                       ))}
                     </span>
                   </li>
                 </ul>

                 <blockquote>
                   <p style={{ whiteSpace: "pre-wrap" }}>
                     {stripHtml(postData.excerpt)}
                   </p>
                 </blockquote>
                 <div className="blog-desc mb-40">
                   <p></p>
                 </div>
                 <div className="blog-img mb-40">
                   <img
                     src={postData.notizie_altro?.innerimage?.mediaItemUrl}
                     alt=""
                   />
                 </div>
                 <div className="blog-desc mb-40">
                   <div
                     dangerouslySetInnerHTML={createMarkup(postData.content)}
                   />
                   {videoId && (
                     <iframe
                       width="100%"
                       height="315"
                       src={`https://www.youtube.com/embed/${videoId}`}
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                       allowFullScreen
                       title={postData.title}
                     ></iframe>
                   )}
                 </div>
                 <div className="ps-navigation" style={{ border: "none" }}>
                   <ul>
                     <li>
                       <Link to="#">
                         {postData.notizie_altro?.uploadFile?.mediaItemUrl ? (
                           <span className="next-link">
                             Allegati <i className="flaticon-next"></i>
                           </span>
                         ) : (
                           " "
                         )}
                       </Link>
                     </li>
                     <li>
                       <a
                         href={
                           postData.notizie_altro?.uploadFile?.mediaItemUrl ||
                           ""
                         }
                         title="allegato"
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         {postData.notizie_altro?.uploadFile?.title || ""}
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
           <div className="col-lg-4 col-md-12">
             <div className="widget-area">
               <SinglePostSidebar />
             </div>
           </div>
         </div>
       </div>
     </div>
     {/* Blog Details End */}

     <Footer
       footerClass="rs-footer home9-style main-home"
       footerLogo={footerLogo}
     />

     {/* scrolltop-start */}
     <ScrollToTop scrollClassName="scrollup orange-color" />
     {/* scrolltop-end */}
   </React.Fragment>
 );
}

export default Post;
