import React from 'react';
import CourseSidebar from './CourseSidebar';
import CourseDetailsTab from './CourseDetailsTab';

const CourseDetailsPart = (props) => {
const { foto1, foto2, foto3, fileDoc, fileLibri, filePro, descrizione, titolo} = props;
    return (
      <React.Fragment>
        <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
          <div className="container">
            <div className="row clearfix">
              <div className="col-lg-8 md-mb-50">
                <CourseDetailsTab
                  fileDoc={fileDoc}
                  fileLibri={fileLibri}
                  filePro={filePro}
                  descrizione={descrizione}
                  titolo={titolo}
                />
              </div>
              <div className="video-column col-lg-4">
                <CourseSidebar
                  parentMenu="course"
                  foto1={foto1}
                  foto2={foto2}
                  foto3={foto3}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default CourseDetailsPart;