import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SingleEvent from "../../components/Events/SingleEvent";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import ScrollToTop from "../../components/Common/ScrollTop";
import favIcon from "../../assets/img/fav.png";
import Logo from "../../assets/img/logo/logo.png";
import footerLogo from "../../assets/img/logo/logo.png";
import StikyLogo from "../../assets/img/logo/logo-verde.png";

const Event = () => {
  const [eventData, seteventData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 6;
  const totalPages = Math.ceil(eventData.length / eventsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };
  const fetcheventData = async () => {
    const query = `
    {
  posts(where: {categoryId: 5}) {
    nodes {
      title
      date
      excerpt
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      notizie_eventi {
        data
        fine
        immagine {
          mediaItemUrl
        }
        inizio
        location
      }
      slug
      categories {
        nodes {
          name
        }
      }
    }
  }
}
`;

    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();

      if (response.ok) {
        seteventData(result.data?.posts.nodes);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetcheventData();
  }, []);

  if (!eventData) {
    return <div>Caricamento in corso...</div>;
    
  }

 const indexOfLastEvent = currentPage * eventsPerPage;
 const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
 const currentEvents = eventData.slice(indexOfFirstEvent, indexOfLastEvent);


  return (
    <React.Fragment>
      <Helmet>
        <title>Eventi | Istituto paritario Poliziano</title>
        <link rel="icon" href={favIcon} />
        <meta
          name="description"
          content="Eventi che si svolgono al Poliziano"
        />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu={100}
        secondParentMenu="blogSingle"
        headerNormalLogo={Logo}
        headerStickyLogo={StikyLogo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
      />

      <hr />
      <div className="rs-event orange-style pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          <div className="flexevent">
            {currentEvents
              .sort(
                (a, b) =>
                  new Date(b.notizie_eventi?.data) -
                  new Date(a.notizie_eventi?.data)
              )
              .slice(0, 3)
              .map((value, i) => (
                <SingleEvent
                  eventClass="event-item home12-style"
                  eventImg={
                    value.featuredImage?.node?.mediaItemUrl ||
                    value.notizie_eventi?.immagine?.mediaItemUrl
                  }
                  eventImg2={value.notizie_eventi?.immagine?.mediaItemUrl}
                  eventTitle={value.title}
                  eventLocation={value.notizie_eventi?.location}
                  eventDate={value.notizie_eventi?.data}
                  eventScheduleStart={value.notizie_eventi?.inizio}
                  eventScheduleEnd={value.notizie_eventi?.fine}
                  eventSlug={value.slug}
                  eventCategory={value.categories?.nodes[0].name}
                  key={i}
                />
              ))}
          </div>
          <div className="pagination">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="buttonpagination"
            >
              Previous
            </button>

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={
                  currentPage === index + 1
                    ? "active buttonpagination"
                    : "buttonpagination"
                }
              >
                {index + 1}
              </button>
            ))}

            <button
              className="buttonpagination"
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default Event;
