import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { stripHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import HeaderStyleSix from "../../components/Layout/Header/HeaderStyleSix";
import OffWrap from "../../components/Layout/Header/OffWrap";
import HomeTwelveMain from "./HomeTwelveMain";
import Footer from "../../components/Layout/Footer/Footer";
import logo from "../../assets/img/logo/logo.png";
import favIcon from "../../assets/img/fav.png";
import CanvasLogo from "../../assets/img/logo/logo-verde.png";
import StikyLogo from "../../assets/img/logo/logo-verde.png";

const Home = () => {
  const [homeData, setHomeData] = useState({
    email: "",
    phonenumber: "",
    indirizzo: "",
    descrizioneMeta: "",
    titoloMeta: "",
  });

 
  const fetchHomeData = async () => {
 
    const query = `{
  pageBy(pageId: 10) {
    headery {
      descrizioneMeta
      email
      
      indirizzo
    
      phonenumber
      titoloMeta
     
    }
  }
}`;

    try {
      // Esegui la richiesta all'endpoint GraphQL di WordPress
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      // Estrai i dati dalla risposta
      const result = await response.json();


      if (response.ok) {
        // Imposta i dati nello stato
          setHomeData(result.data?.pageBy.headery);
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchHomeData();
  }, []);

if (!homeData) {
  return <div>Caricamento in corso...</div>;
}

  const email = homeData ? homeData.email : '';
  const phoneNumber = homeData ? homeData.phonenumber : '';
  const Location = homeData ? homeData.indirizzo : '';
  const titoloMeta = homeData ? homeData.titoloMeta : "";
  const descrizioneMeta = homeData ? homeData.descrizioneMeta : "";


  return (
    <React.Fragment>
      <Helmet>
        <title>{titoloMeta}</title>
        <meta name="description" content={stripHtml(descrizioneMeta)} />
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <HeaderStyleSix
        parentMenu="homex"
        headerNormalLogo={logo}
        headerStickyLogo={StikyLogo}
        mobileNormalLogo={logo}
        CanvasLogo={CanvasLogo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home1-modifiy home12-modifiy"
        TopBar="enable"
        TopBarClass="topbar-area home11-topbar"
        phoneNumber={phoneNumber}
        emailAddress={email}
        Location={Location}
      />
      <HomeTwelveMain />
      <Footer
        footerClass="rs-footer home9-style home12-style"
        footerLogo={logo}
      />
    </React.Fragment>
  );
};

export default Home;
