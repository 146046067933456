import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ContactForm from "/Users/angelomontini/Documents/React/educavo-react/src/pages/home/ContactForm";
import SinglePostSidebar from "./SinglePostSidebar";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import { stripHtml } from "/Users/angelomontini/Documents/React/educavo-react/src/utils.js"; 
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";


// Image
import favIcon from "../../assets/img/fav.png";
import Logo from "../../assets/img/logo/logo.png";
import footerLogo from "../../assets/img/logo/logo.png";
import StikyLogo from "../../assets/img/logo/logo-verde.png";

function getYoutubeVideoId(url) {
  if (!url) {
    return null; 
  }
  const parts = url.split("=");
  if (parts.length > 1) {
    return parts[1];
  } else {
    return null;
  }
}


const SinglePostLeftSidebar = () => {
       const history = useHistory();
       const [error, setError] = useState(null);
       const [loading, setLoading] = useState(true);
       const { slug } = useParams();
       const [eventData, seteventData] = useState([]);
       
  useEffect(() => {
    const fetcheventData = async () => {
      const query = `
  {
  post(idType: SLUG, id: "${slug}") { 
    featuredImage {
      node {
        mediaItemUrl
      }
    }
    notizie_eventi {
      video
      data
      file {
        mediaItemUrl
      }
      fine
      inizio
      immagine {
        mediaItemUrl
      }
      location
    }
    content
    excerpt
    title
  }
}
`;

      try {
        const response = await fetch(
          "https://gestione.istitutopoliziano.it/homo",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ query }),
          }
        );

        const result = await response.json();
        if (response.ok && result.data?.post) {
          seteventData(result.data.post);
        } else {
          history.push("/404");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetcheventData();
  }, [slug, history]);


  if (loading) {
    return <div>Caricamento in corso. Grazie</div>;
  }

  if (error) {
    return <div>Errore: {error}</div>;
  }

  let videoId = null;
  if (eventData && eventData.notizie_eventi && eventData.notizie_eventi.video) {
    videoId = getYoutubeVideoId(eventData.notizie_eventi.video);
  }


  return (
    <React.Fragment>
      <Helmet>
        <title>
          {eventData
            ? `${eventData.title} | Istituto paritario Poliziano`
            : "Istituto paritario Poliziano"}
        </title>
        <link rel="icon" href={favIcon} />
        <meta name="description" content={stripHtml(eventData.excerpt)} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu={100}
        secondParentMenu="blogSingle"
        headerNormalLogo={Logo}
        headerStickyLogo={StikyLogo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
      />

      <SiteBreadcrumb
        pageTitle={eventData.title}
        pageCategory="Eventi"
        linkBread="/event"
        pageName="Evento Singolo"
      />

      {/* Blog Details Start */}
      <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 pr-50 md-pr-14">
              <div className="blog-deatails">
                <div className="bs-img">
                  <img
                    src={
                      eventData.featuredImage?.node?.mediaItemUrl ||
                      eventData.notizie_eventi?.immagine?.mediaItemUrl
                    }
                    alt={eventData.title}
                  />
                </div>
                <div className="blog-full">
                  <ul className="single-post-meta">
                    <li>
                      <span className="p-date">
                        {" "}
                        <i className="fa fa-calendar-check-o"></i>{" "}
                        {eventData.notizie_eventi?.data}
                      </span>
                    </li>

                    <li className="Post-cate">
                      <span className="tag-line">
                        <i className="fa fa-tag"></i>
                        {eventData.notizie_eventi?.location}
                      </span>
                    </li>
                  </ul>

                  <blockquote>
                    <p>{stripHtml(eventData.excerpt)}</p>
                  </blockquote>
                  <div className="blog-desc mb-40">
                    <p></p>
                  </div>
                  <div className="blog-img mb-40">
                    <img
                      src={eventData.notizie_eventi?.immagine?.mediaItemUrl}
                      alt={eventData.title}
                    />
                  </div>
                  <div className="blog-desc mb-40">
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {stripHtml(eventData.content)}
                    </p>
                    {videoId && (
                      <iframe
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={eventData.title}
                      ></iframe>
                    )}
                  </div>
                </div>
              </div>
              <div className="ps-navigation">
                <ul>
                  <li>
                    <Link to="#">
                      {eventData.notizie_eventi?.file?.mediaItemUrl ? (
                        <span className="next-link">
                          Allegati <i className="flaticon-next"></i>
                        </span>
                      ) : (
                        " "
                      )}
                    </Link>
                  </li>
                  <li>
                    <a
                      href={eventData.notizie_eventi?.file?.mediaItemUrl || ""}
                      title="allegato"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {eventData.notizie_eventi?.file?.title ||
                        "Programma allegato"}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-12 padding-0">
                <div className="rs-free-contact">
                  <div className="sec-title3">
                    <h2 className="title white-color">
                      Informazioni sull'evento
                    </h2>
                  </div>
                  <ContactForm />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="widget-area">
                <SinglePostSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog Details End */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default SinglePostLeftSidebar;
