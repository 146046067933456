import React from 'react';
import { Link} from "react-router-dom";
import BG from "/Users/angelomontini/Documents/React/educavo-react/src/assets/img/bg/lavagna.jpeg";

const SiteBreadcrumb = (props) => {

	const { breadcrumbsClass, innerClass, titleClass, pageTitle, parentCategory, pageCategory, linkBread, pageName, breadcrumbsImg } = props;

	return (
    <div
      className={
        breadcrumbsClass
          ? breadcrumbsClass
          : "rs-breadcrumbs breadcrumbs-overlay"
      }
    >
      <div className="breadcrumbs-img">
        <img src={breadcrumbsImg || BG} alt="briciole" />
      </div>
      <div className={innerClass ? innerClass : "breadcrumbs-text white-color"}>
        <h1 className={titleClass ? titleClass : "page-title"}>
          {pageTitle ? pageTitle : "Istituto paritario Poliziano"}
        </h1>
        <ul>
          <li>
            <Link to="/" className="active">
              {parentCategory ? parentCategory : "Home"}
            </Link>
          </li>
          <li>
            <Link to={linkBread ? linkBread : "/event"} className="active">
              {pageCategory ? pageCategory : "Category"}
            </Link>
          </li>
          <li>{pageName ? pageName : "Page Name"}</li>
        </ul>
      </div>
    </div>
  );
}

export default SiteBreadcrumb;




