import React from "react";

const SectionTitle = (props) => {
  const {
    sectionClass,
    subtitleClass,
    subtitle,
    titleClass,
    title,
    descClass,
    description,
    secondDescClass,
    secondDescription,
    allegato,
    titoloAllegato,
    allegato2,
    titoloAllegato2,
    allegato3,
    titoloAllegato3,
    allegato4,
    titoloAllegato4,
    allegato5,
    titoloAllegato5,
    allegato6,
    titoloAllegato6,
    allegato7,
    titoloAllegato7,
    allegato8,
    titoloAllegato8,
    allegato9,
    titoloAllegato9,
    allegato10,
    titoloAllegato10,
    effectClass,
  } = props;

  return (
    <div className={sectionClass}>
      <div className={subtitleClass}>
        {subtitle ? subtitle : "Istituto Poliziano "}
      </div>
      <h2 className={titleClass}>{title ? title : "Poliziano"}</h2>
      <p className={descClass}>
        {description ? description : "sintesi della pagina"}
      </p>
      <p className={secondDescClass} style={{ whiteSpace: "pre-wrap" }}>
        {secondDescription ? (
          <span dangerouslySetInnerHTML={{ __html: secondDescription }} />
        ) : (
          " "
        )}
      </p>

      <div class="ol">
        <a href={allegato ? allegato : "#"}>
          <p>{titoloAllegato ? titoloAllegato : ""}</p>
        </a>

        <a href={allegato2 ? allegato2 : "#"}>
          <p>{titoloAllegato2 ? titoloAllegato2 : ""}</p>
        </a>

        <a href={allegato3 ? allegato3 : "#"}>
          <p>{titoloAllegato3 ? titoloAllegato3 : ""}</p>
        </a>

        <a href={allegato4 ? allegato4 : "#"}>
          <p>{titoloAllegato4 ? titoloAllegato4 : ""}</p>
        </a>

        <a href={allegato5 ? allegato5 : "#"}>
          <p>{titoloAllegato5 ? titoloAllegato5 : ""}</p>
        </a>

        <a href={allegato6 ? allegato6 : "#"}>
          <p>{titoloAllegato6 ? titoloAllegato6 : ""}</p>
        </a>

        <a href={allegato7 ? allegato7 : "#"}>
          <p>{titoloAllegato7 ? titoloAllegato7 : ""}</p>
        </a>

        <a href={allegato8 ? allegato8 : "#"}>
          <p>{titoloAllegato8 ? titoloAllegato8 : ""}</p>
        </a>

        <a href={allegato9 ? allegato9 : "#"}>
          <p>{titoloAllegato9 ? titoloAllegato9 : ""}</p>
        </a>

        <a href={allegato10 ? allegato10 : "#"}>
          <p>{titoloAllegato10 ? titoloAllegato10 : ""}</p>
        </a>
      </div>
      <div className={effectClass}></div>
    </div>
  );
};

export default SectionTitle;
