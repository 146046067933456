import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CanvasGallery from "./CanvasGallery";

const CanvasLaterale = (props) => {
  const { canvasClass, canvasLogo } = props;

  const canvasMenuRemove = () => {
    document.body.classList.remove("nav-expanded");
  };

  const [fotoData, setfotoData] = useState([]);

  useEffect(() => {
    const fetchfotoData = async () => {
      const query = `
     {
  page(id: "342", idType: DATABASE_ID) {
    fotoGalleria {
      imamgine1 {
        mediaItemUrl
      }
      imamgine2 {
        mediaItemUrl
      }
      imamgine3 {
        mediaItemUrl
      }
      imamgine4 {
        mediaItemUrl
      } 
      imamgine5 {
        mediaItemUrl
      }
      imamgine6 {
        mediaItemUrl
      } 
      imamgine7 {
        mediaItemUrl
      }
      imamgine8 {
        mediaItemUrl
      }
      imamgine9 {
        mediaItemUrl
      }
    }
  }
}
      `;

      try {
        const response = await fetch(
          "https://gestione.istitutopoliziano.it/homo",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ query }),
          }
        );

        // Estrai i dati dalla risposta
        const result = await response.json();

        if (response.ok) {
          setfotoData(result.data?.page);
        } else {
          console.error("Errore nella richiesta GraphQL:", result.errors);
        }
      } catch (error) {
        console.error("Errore nella richiesta di rete:", error);
      }
    };
    fetchfotoData();
  }, []);
 
  if (!fotoData) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    <React.Fragment>
      <nav className={canvasClass}>
        <div className="close-btn">
          <div onClick={canvasMenuRemove} id="nav-close">
            <div className="line">
              <span className="line1"></span>
              <span className="line2"></span>
            </div>
          </div>
        </div>
        <div className="canvas-logo">
          <Link to="/">
            <img src={canvasLogo} alt="logo" />
          </Link>
        </div>
        <CanvasGallery
          ima1={fotoData.fotoGalleria?.imamgine1?.mediaItemUrl}
          ima2={fotoData.fotoGalleria?.imamgine2?.mediaItemUrl}
          ima3={fotoData.fotoGalleria?.imamgine3?.mediaItemUrl}
          ima4={fotoData.fotoGalleria?.imamgine4?.mediaItemUrl}
          ima5={fotoData.fotoGalleria?.imamgine5?.mediaItemUrl}
          ima6={fotoData.fotoGalleria?.imamgine6?.mediaItemUrl}
          ima7={fotoData.fotoGalleria?.imamgine7?.mediaItemUrl}
          ima8={fotoData.fotoGalleria?.imamgine8?.mediaItemUrl}
          ima9={fotoData.fotoGalleria?.imamgine9?.mediaItemUrl}
        />

{/*         <div className="map-img">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2960.7676432666244!2d12.495785195312193!3d41.87034224693037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61d909aa88ff%3A0x9034833f18b73cf2!2sIstituto%20Paritario%20%E2%80%9CA.%20POLIZIANO%E2%80%9D!5e0!3m2!1sen!2sit!4v1701186163401!5m2!1sen!2sit"
            width="100%"
            height="450"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="mappa"
            style={{ border: "0" }}
          ></iframe>
        </div> */}
        <div className="canvas-contact">
          <ul className="social">
            <li>
              <a href="/">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>

            <li>
              <a href="/">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default CanvasLaterale;
