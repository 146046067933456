import React from 'react';
import { Link } from 'react-router-dom';

const SingleEvent = (props) => {
    const {
      eventClass,
      eventImg,
      eventImg2,
      eventCategory,
      eventLocation,
      eventDate,
      eventTitle,
      eventDesc,
      eventScheduleStart,
      eventScheduleEnd,
      eventSlug
    } = props;
    return (
      <div className={eventClass ? eventClass : "event-item"}>
        <div className="event-short">
          <div className="featured-img">
            <Link
              to={`/eventi/${eventSlug ? eventSlug : ""}`}
              title={eventSlug}
            >
              <img
                src={eventImg ? eventImg : eventImg2}
                alt={eventTitle}
                style={{ objectFit: "cover", width: "100%", height: "300px" }}
              />
            </Link>
          </div>
          {eventCategory ? (
            <div className="categorie">
              <Link
                to={`/eventi/${eventSlug ? eventSlug : ""}`}
                title={eventSlug}
              >
                {eventCategory}
              </Link>
            </div>
          ) : (
            ""
          )}

          <div className="content-part">
            <div className="all-dates-time">
              <div className="address">
                <i className="fa fa-map-o"></i>{" "}
                {eventLocation ? eventLocation : " "}
              </div>
              {eventScheduleStart ? (
                <div className="time">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                  {eventScheduleStart} - {eventScheduleEnd}
                </div>
              ) : (
                ""
              )}
            </div>
            <h4 className="title">
              <Link
                to={`/eventi/${eventSlug ? eventSlug : ""}`}
                title={eventSlug}
              >
                {eventTitle ? eventTitle : "Evento del Poliziano"}
              </Link>
            </h4>
            {eventDesc ? <p className="text">{eventDesc}</p> : ""}
            <div className="event-btm">
              <div className="date-part">
                <div className="date">
                  <i className="fa fa-calendar-check-o"></i>
                  {eventDate ? eventDate : "July 24, 2020"}
                </div>
              </div>
              <div className="btn-part">
                <Link
                  to={`/eventi/${eventSlug ? eventSlug : ""}`}
                  title={eventSlug}
                >
                  Leggi
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SingleEvent