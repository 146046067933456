import React from 'react';
import { Link } from 'react-router-dom';

 

const CourseSingle = (props) => {
    return (
      <div className={props.itemClass}>
        <div className="courses-grid">
          <div className="img-part">
            <Link to={props.courseLink}>
              <img
                src={props.image}
                alt={props.title}
                style={{ objectFit: "cover", width: "100%", height: "300px" }}
              />
            </Link>
          </div>
          <div className="content-part">
            <div className="course-price">
              <span className="price">
                {props.sigla}{" "}
                <a href={props.courseLink}>
                  <i className="fa fa-link"></i>
                </a>
              </span>
            </div>
            <h3 className="title">
              <Link to={props.courseLink}>{props.title}</Link>
            </h3>
            <div className="meta-part">
              <span className="course-qnty">
                {props.sintesi2 || `Corso di studio dell'Istituto Poliziano`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CourseSingle