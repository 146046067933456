
import { Link } from 'react-router-dom';

const SinglePostTwo = (props) => {
    const {
      blogClass,
      blogImage,
      blogTitle,
      blogAuthor,
      blogPublishedDate,
      blogDesc,
      blogButtonClass,
      blogButtonText,
      blogSlug,
    } = props;
    return (
      <div
        className={
          blogClass
            ? blogClass
            : "row align-items-center no-gutter white-bg blog-item mb-30"
        }
      >
        <div className="col-md-6">
          <div className="image-part">
            <Link to={`/blog/${blogSlug ? blogSlug : ""}`} title={blogSlug}>
              <img src={blogImage} alt={blogTitle} />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="blog-content">
            <ul className="blog-meta">
              <li className="admin">
                <i className="fa fa-user-o"></i>{" "}
                {blogAuthor ? blogAuthor : "Admin"}
              </li>
              <li className="date">
                <i className="fa fa-calendar-check-o"></i>{" "}
                {blogPublishedDate ? blogPublishedDate : " "}
              </li>
             
            </ul>
            <h3 className="title">
              <Link to={`/blog/${blogSlug ? blogSlug : ""}`} title={blogSlug}>
                {blogTitle ? blogTitle : ""}
              </Link>
            </h3>
            <p>{blogDesc}</p>
            <div className="btn-part">
              <Link
                to={`/blog/${blogSlug ? blogSlug : ""}`}
                title={blogSlug}
                className={blogButtonClass ? blogButtonClass : "readon-arrow"}
              >
                {blogButtonText ? blogButtonText : "Leggi"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SinglePostTwo