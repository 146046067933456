import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import galleryLogo1 from "../../../assets/img/gallery/1.jpg";
import galleryLogo2 from "../../../assets/img/gallery/2.jpg";
import galleryLogo3 from "../../../assets/img/gallery/3.jpg";
import galleryLogo4 from "../../../assets/img/gallery/4.jpg";
import galleryLogo5 from "../../../assets/img/gallery/5.jpg";
import galleryLogo6 from "../../../assets/img/gallery/6.jpg";
import galleryLogo7 from "../../../assets/img/gallery/1.jpg";
import galleryLogo8 from "../../../assets/img/gallery/2.jpg";
import galleryLogo9 from "../../../assets/img/gallery/4.jpg";


const CanvasGallery = (props) => {
  const { ima1, ima2, ima3, ima4, ima5, ima6, ima7, ima8, ima9 } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


const photos = [
  {
    src: ima1 || galleryLogo1,
    width: 1,
    height: 1,
  },
  {
    src: ima2 || galleryLogo2, 
    width: 1,
    height: 1,
  },
  {
    src: ima3 || galleryLogo3,
    width: 1,
    height: 1,
  },
  {
    src: ima4 || galleryLogo4,
    width: 1,
    height: 1,
  },
  {
    src: ima5 || galleryLogo5,
    width: 1,
    height: 1,
  },
  {
    src: ima6 || galleryLogo6,
    width: 1,
    height: 1,
  },
  {
    src: ima7 || galleryLogo7,
    width: 1,
    height: 1,
  },
  {
    src: ima8 || galleryLogo8,
    width: 1,
    height: 1,
  },
  {
    src: ima9 || galleryLogo9,
    width: 1,
    height: 1,
  },
];

  return (
    <div className="offcanvas-gallery">
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};


export default CanvasGallery;