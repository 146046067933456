import React from "react";

function PDFViewer({ pdfUrl }) {
  return (
    <iframe
      src={pdfUrl}
      style={{ width: "100%", height: "1000px" }}
      title="PDF Viewer"
    ></iframe>
  );
}

const ProPart = (props) => {
  const { filePro } = props;

  return (
    <div className="content pt-30 pb-30 pl-30 pr-30 white-bg">
      <PDFViewer pdfUrl={filePro} />
    </div>
  );
};
export default ProPart;
