import React from 'react';
import RecentEvent from './SidebarEventiLat';
import RecentPost from "./SidebarPostLat";

import CategoriesWidget from '../../components/Widget/CategoriesWidget';

const SinglePostSidebar = () => {

    return (
      <>
        <RecentPost />
        <RecentEvent />

        <CategoriesWidget />
      </>
    );
}

export default SinglePostSidebar;