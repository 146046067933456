import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/Common/SectionTitle';

// Team Members

const Team = () => {

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type='button' onClick={onClick} className={className}><i className="flaticon-right-arrow"></i></button>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type='button' onClick={onClick} className={className}> <i className="flaticon-left-arrow"></i></button>
        );
    }

    const slilderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        className: "active",
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-team style1 nav-style2 orange-color pt-94 pb-100 md-pt-64 md-pb-70 gray-bg">
                <div className="container">
                    <div className="row y-middle mb-50 md-mb-30">
                        <div className="col-md-6 sm-mb-30">
                            <SectionTitle
                                sectionClass="sec-title"
                                subtitleClass="sub-title orange"
                                subtitle="Instrructor"
                                titleClass="title mb-0"
                                title="Expert Teachers"
                            />
                        </div>
                    </div>
                    <Slider {...slilderSettings}>
                     

                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Team;