
import { Link } from 'react-router-dom';

const SinglePost = (props) => {
    const { blogClass, blogImage, blogTitle, contentClass, blogDesc, blogCategory, blogAuthor, blogPublishedDate, blogButtonClass, blogButtonText, blogSlug } = props;
    return (
      <div className={blogClass ? blogClass : "blog-item mb-44"}>
        <div className="image-part">
          <Link to={`/blog/${blogSlug ? blogSlug : ""}`} title={blogSlug}>
            <img src={blogImage} alt={blogTitle} />
          </Link>
        </div>
        <div className={contentClass ? contentClass : "blog-content"}>
          <ul className="blog-meta">
            <li className="date">
              <i className="fa fa-calendar-check-o"></i>{" "}
              {blogPublishedDate ? blogPublishedDate : ""}
            </li>
            <li className="admin">
              <i className="fa fa-user-o"></i>{" "}
              {blogAuthor ? blogAuthor : "admin"}
            </li>
          </ul>
          <h3 className="title">
            <Link to={`/blog/${blogSlug ? blogSlug : ""}`} title={blogSlug}>
              {blogTitle
                ? blogTitle
                : " "}
            </Link>
          </h3>
          <div className="desc">
            {blogDesc
              ? blogDesc
              : ""}
          </div>
          <div className="btn-btm">
            <div className="cat-list">
              <ul className="post-categories">
                <li>
                  <Link to="/blog">
                    {blogCategory ? blogCategory : ""}
                  </Link>
                </li>
              </ul>
            </div>
            <div className={blogButtonClass ? blogButtonClass : "rs-view-btn"}>
              <Link to={`/blog/${blogSlug ? blogSlug : ""}`} title={blogSlug}>
                {blogButtonText ? blogButtonText : "Leggi"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SinglePost